<template>
  <div class="row">
    <div class="col-sm-12 mt-5">
      <FullCalendar class='demo-app-calendar' :options='calendarOptions'>
      <template v-slot:eventContent='arg'>
        <b>{{ arg.timeText }} &nbsp;</b>
        <i>{{ arg.event.title }}</i>
      </template>
    </FullCalendar>
    </div>
  </div>
</template>

<script src='../assets/locale/th'></script>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import esLocale from '@fullcalendar/core/locales/th';

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  components: {
    FullCalendar // make the <FullCalendar> tag available
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin,],
        initialView: 'dayGridMonth',
        dateClick: this.handleDateClick,
        weekends: true,
        eventClick: this.handleEventClick,
        views: {
    dayGridMonth: {
      dayHeaderFormat: {
        weekday: 'long'
      }
    }
  },
        headerToolbar: {
          left: 'prev,next',
          center: 'title',
          right: 'timeGridWeek,dayGridMonth'
        },
        height:850,
        locale: esLocale,
        scrollTime: '08:00',
        omitZeroMinute: false,
        slotLabelFormat:
        {
          hour: 'numeric',
          minute: '2-digit',
          omitZeroMinute: false,
          hour12: false
        },
        events: [
          { title: 'Meeting', start: new Date() }
        ]

      },
    };
  },
  mounted() {
  },
  methods: {
    handleDateClick: function (arg) {
      // console.log(arg);
      var breaktime = new Date(arg.dateStr)

      var d = breaktime.getFullYear() + '-' + (parseInt(breaktime.getMonth()) + 1).toString().padStart(2, "0") + '-' + breaktime.getDate().toString().padStart(2, "0")
      console.log(d);
    },
    handleEventClick(clickInfo) {
      console.log(clickInfo.event);
      var breaktime = new Date(clickInfo.event.start)

      var d = breaktime.getFullYear() + '-' + (parseInt(breaktime.getMonth()) + 1).toString().padStart(2, "0") + '-' + breaktime.getDate().toString().padStart(2, "0")
      console.log(d);
      
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
img {
  display: inline !important;
}
.qrcode {
  display: inline-block !important;
  font-size: 0;
  margin-bottom: 0;
  position: relative;
}
</style>
