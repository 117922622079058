<template>
  <div class="container mt-5">
      <vue-editor              
              use-custom-image-handler
              @image-added="handleImageAdded"
              v-model="data.text"
            />
  </div>
</template>

<script src='../assets/locale/th'></script>

<script>
import LinkService from '../services/LinkService'
import axios from "axios";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      data:{}
    };
  },
  mounted() {
  },
  methods: {
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData();
      formData.append("file", file);
      var http = 
LinkService.geturl()+ "/uploadimg?name=" + file.name;
      axios
        .post(http, formData)
        .then(() => {
      Editor.insertEmbed(
        cursorLocation,
        "image",        
LinkService.geturl()+"/uploads/img/" + file.name
      );
      resetUploader();
      var im = this.data.text.split('img')
      this.data.text = im[0]+'img style="width:30%;"'+im[1]
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
img {
  display: inline !important;
}
.qrcode {
  display: inline-block !important;
  font-size: 0;
  margin-bottom: 0;
  position: relative;
}
</style>
