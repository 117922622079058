<template>
  <div>
    <div class="form-group mt-5 container-fluid">
      <label for="exampleInputEmail1">Date : </label>&nbsp;
      <input type="date" v-model="date" @change="changefirms()" />
    </div>

    <div class="container-fluid mb-3">
      <button
        type="button"
        :class="'btn-lg ' + modisclass"
        @click="changtype(1)"
        style="width: 150px"
      >
        <img src="https://ci2ict.com/map_red.png" alt="" />&nbsp;MODIS</button
      >&nbsp;
      <button
        type="button"
        :class="'btn-lg ' + viirsclass"
        @click="changtype(2)"
        style="width: 150px"
      >
        <img src="https://ci2ict.com/map_blue.png" alt="" />&nbsp;VIIRS
      </button>
      &nbsp;
      <button
        type="button"
        :class="'btn-lg ' + allclass"
        @click="changtype(3)"
        style="width: 150px"
      >
        <img src="https://ci2ict.com/map_red.png" alt="" /><img
          src="https://ci2ict.com/map_blue.png"
          alt=""
        />&nbsp;All
      </button>
    </div>
    <longdo-map
      :zoom="10"
      v-if="markers"
      :location="{ lon: 100.19, lat: 19.22 }"
      :lastView="false"
    >
      <longdo-map-marker
        v-for="(item, i) in markers"
        :key="i"
        :id="item.id"
        :location="item.location"
        :title="item.title"
        :icon="item.icon"
        :detail="item.detail"
      />
    </longdo-map>
  </div>
</template>

<script>
// import longdo from "longdo-map-vue";
import FirmsService from "../services/FirmsService";

export default {
  name: "App",
  components: {},
  data() {
    return {
      show: false,
      loncurrent: "",
      locat: {},
      locationList: [
        { lon: 99, lat: 14 },
        { lon: 100, lat: 13 },
        { lon: 102, lat: 13 },
        { lon: 103, lat: 14 },
      ],
      markers: [],
      series: [],
      numthreethree: [],
      numsixtwofive: [],
      numsixthree: [],
      numseven: [],
      numsevenfive: [],
      table: [],
      chartOptions: {},
      detail: [],
      month: [],
      sumthreethree: 0,
      sumsixtwofive: 0,
      sumsixthree: 0,
      sumseven: 0,
      sumsevenfive: 0,
      total: 0,
      checkreport: [],
      date:'',
      modisclass:'btn btn-secondary',
      viirsclass:'btn btn-secondary',
      allclass:'btn btn-primary',
      satellite:''
    };
  },
  methods: {
    changtype(id){
if (id == 1) {
 this.modisclass='btn btn-primary',
      this.viirsclass='btn btn-secondary'
      this.allclass='btn btn-secondary'
      this.satellite = 'MODIS'
}
else if(id == 2){
  this.viirsclass='btn btn-primary',
      this.modisclass='btn btn-secondary'
      this.satellite = 'VIIRS'
      this.allclass='btn btn-secondary'
}else{
  this.viirsclass='btn btn-secondary',
      this.modisclass='btn btn-secondary'
      this.allclass='btn btn-primary'
      this.satellite = ''
}
    
this.loadmarker()
    },
    changefirms(){
// console.log(this.date);
this.loadmarker()
    },
    go() {},
    loadMap(map) {
      // console.log(map);
      // console.log(map.location());
      this.loncurrent = map.location().lon;
      this.latcurrent = map.location().lat;
    },
    event(map) {
      // console.log(map);
      map.Event.bind("overlayClick", function (overlayClick) {
        // console.log(overlayClick.title);
        // console.log(overlayClick);
        // var title = overlayClick.title;
        var lon = overlayClick.location().lon
        var lat = overlayClick.location().lat
        // console.log(lon,lat);
        FirmsService.getfirms('',lat,lon,'').then(() => {
          // console.log(res.data);
          // window.open(
          //   "/detail/"+res.data[0].id,
          //   "_blank" // <- This is what makes it open in a new window.
          // );
        });
      });
    
    },
    loadmarker() {
      this.markers = []
        FirmsService.getmap(56,this.date,this.satellite)
        .then((res) => {
          // console.log(res.data);
          var map = res.data;
          for (let i = 0; i < map.length; i++) {
            var full = ''
            if (map[i].aoi != null) {
              full += map[i].aoi
            }
             full += ' '+ map[i].subdistrict+' '+ map[i].district+' '+ map[i].province
            // var desc =
            //   '<iframe src="map/' +
            //   map[i].aoi +
            //   '" height="200" width="200" title="Iframe Example"></iframe>';
            this.markers.push({
              location: { lon: map[i].longitude, lat: map[i].latitude },
              title: full,
              id:map[i].id,
              detail: 'date : '+ map[i].dateTH+'<br>'+'daynight : '+ map[i].daynight+'<br>'+'brightness : '+ map[i].brightness+'<br>'+'scan : '+ map[i].scan+'<br>'+'instrument : '+ map[i].instrument +'<br>'+'satellite : '+ map[i].satellite +'<br>',
              icon: { url: map[i].icon },
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
      // console.log(this.markers);
      // });
      
    },
  },
  mounted() {
    // console.log(this.currentUser);

//     var d = new Date()
//       var date = d.getFullYear()+'-'+(parseInt(d.getMonth()) + 1).toString().padStart(2, "0")+'-'+(parseInt(d.getDate())-1).toString().padStart(2, "0")
// date = '2024-03-12'
    this.date = '2024-03-10'
    this.loadmarker();
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<style>
html,
body {
  font-size: 9px !important;
  height: 100%;
  margin: 0px;
}
div {
  font-size: 14px;
}
.ldmap_placeholder {
  position: relative;
  width: 100%;
  height: 700px !important;
  min-width: 100px;
  min-height: 700px !important;
  background: #eee;
  font: 12px/1.2 Tahoma, sans-serif;
  overflow: hidden;
  z-index: 0;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
}
/* .ldmap_placeholder .ldmap_frame .ldmap_popup {
  min-width: 800px !important;
  min-height: 500px !important;
}
.ldmap_placeholder .ldmap_frame .ldmap_popup_detail {
  max-width: 970% !important;
  max-height: 540px !important;
}
.ldmap_placeholder .ldmap_frame .ldmap_popup_title {
  font-size: 9px !important;
} */
</style>
