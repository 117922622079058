<template>
  <div class="container">
    <div class="form-group mt-5 container-fluid">
    <label for="exampleInputEmail1">Date : </label>&nbsp;
    
    <input type="date" v-model="date">&nbsp;
    <div class="form-group">
    <label for="exampleFormControlSelect1">Satellite</label>
    <select class="form-control" id="exampleFormControlSelect1" v-model="sate">
      <option value="MODIS_NRT">MODIS_NRT</option>
      <option value="VIIRS_NOAA20_NRT">VIIRS_NOAA20_NRT</option>
    </select>
  </div>
  <button type="button" class="btn btn-lg btn-info" @click="changefirms()">Create Location</button>&nbsp;
    <button type="button" class="btn btn-lg btn-secondary" @click="updatefirms()">Update Location</button>
  </div>
  </div>
</template>

<script>
import UrlService from "../services/UrlService";
import axios from "axios";
import FirmsService from "../services/FirmsService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      list: [],
      user: {},
      user_id: 0,
      hash: 0,
      title: "",
      roles: [],
      sate:'MODIS_NRT',
      date:''
    };
  },
  mounted() {
    this.getUsers();
    this.getfirms();
    // this.createfirms()
    // this.updatefirms()
    var d = new Date()
      var date = d.getFullYear()+'-'+(parseInt(d.getMonth()) + 1).toString().padStart(2, "0")+'-'+(parseInt(d.getDate())).toString().padStart(2, "0")
this.date = date
  },
  methods: {
    getfirms(){
      FirmsService.getfirms(56,'','','').then((res) => {
        console.log(res.data);
      });
    },
    updatefirms() {
      var key = "9b8d7c745502db887b835ebe79b8e563";
      FirmsService.getnull('').then(async (res) => {
        console.log(res.data);
        for (let r = 0; r < res.data.length; r++) {
          if (res.data[r].province == null) {
            var lat = res.data[r].latitude;
            var lon = res.data[r].longitude;
            var http =
              "https://api.longdo.com/map/services/address?lon=" +
              lon +
              "&lat=" +
              lat +
              "&noelevation=1&key=" +
              key;

            await axios.get(http).then(async (longdomap) => {
              console.log(res.data[r].id);
              console.log(longdomap.data);
              var updatelocation = {
                aoi: longdomap.data.aoi,
                district: longdomap.data.district,
                geocode: longdomap.data.geocode,
                postcode: longdomap.data.postcode,
                province: longdomap.data.province,
                subdistrict: longdomap.data.subdistrict,
              };
              await FirmsService.updatelocation(res.data[r].id,updatelocation).then(()=>{
if (r+1 == res.data.length) {
  alert('success')
}
              })
            });
          }
           
        }
      });
    },
    changefirms() {
      var mapkey = "39f9a9524cec830609d9fb72c1bf4b15";
      // var source = "VIIRS_NOAA20_NRT";
      // var source = "MODIS_NRT"
      var date = this.date;
      var http =
        "https://firms.modaps.eosdis.nasa.gov/api/country/csv/" +
        mapkey +
        "/" +
        this.sate +
        "/THA/1/" +
        date;
      axios.get(http).then((res) => {
        console.log(res.data);
        var data = res.data.split(/\r?\n/);
        console.log(data[0]);
        // var columns = data[0].split(',')
        // var pd = []
        for (let d = 0; d < data.length; d++) {
          if (d != 0) {
            var dt = data[d].split(",");
            var firm = {
              latitude: dt[1],
              longitude: dt[2],
              brightness: dt[3],
              scan: dt[4],
              track: dt[5],
              acq_date: dt[6],
              acq_time: dt[7],
              satellite: dt[8],
              instrument: dt[9],
              confidence: dt[10],
              version: dt[11],
              bright_t31: dt[12],
              frp: dt[13],
              daynight: dt[14],
            };
            console.log(firm);
            FirmsService.createfirm(firm).then(()=>{
              if (d+1 == data.length) {
              alert('success')
this.updatefirms()
            }
            })
          }
        }
      });
    },
    deleteurl(id) {
      UrlService.deleteurl(id).then(() => {
        this.getUsers();
      });
    },
    getid(id) {
      this.user_id = id;
      if (this.user_id != 0) {
        this.title = "แก้ไขข้อมูล URL";
        // console.log(this.user_id);
        UrlService.geturl(this.user_id).then((res) => {
          // console.log(res.data);
          this.user = res.data;
          this.hash = this.user.password;
        });
      } else {
        this.title = "เพิ่มข้อมูล URL";
        this.user = [];
      }
    },
    save() {
      // if (this.user.firstname == "") {
      //   alert("กรุณากรอกชื่อ");
      // } else if (this.user.lastname == "") {
      //   alert("กรุณากรอกนามสกุล");
      // }else
      if (this.user.url == "" || this.user.url == null) {
        alert("กรุณากรอก URL");
        // } else if (this.user.role_id == "") {
        //   alert("กรุณาเลือกสิทธิ์");
      } else if (this.user.name == "" || this.user.name == null) {
        alert("กรุณากรอกชื่อ URL");
      } else {
        var userdata = {
          url: this.user.url,
          name: this.user.name,
          createdBy: this.currentUser.id,
          status: 1,
        };
        if (this.user_id == 0) {
          UrlService.createurl(userdata).then(() => {
            document.getElementById("closeduser").click();
            this.getUsers();
            //       setTimeout(function () {
            //   location.reload();
            // }, 500);
            // window.scrollTo(0, 0);
          });
        } else {
          // console.log(this.user_id);
          UrlService.updateurl(this.user_id, userdata).then(() => {
            // console.log(res.data);
            document.getElementById("closeduser").click();
            this.getUsers();
            //       setTimeout(function () {
            //   location.reload();
            // }, 500);
            // window.scrollTo(0, 0);
          });
        }
      }
    },
    getUsers() {
      UrlService.geturls("").then((res) => {
        this.list = res.data;
      });
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
</style>
