import http from "../http-common";

class FirmsService {
  createfirm(data) {
    return http.post('/firms', data);
  }
  getfirms(name,lat,lon,date) {
    return http.get(`/firms?name=`+name+'&&lat='+lat+'&&lon='+lon+'&&date='+date);
  }
  getmap(name,date,instrument) {
    return http.get(`/firms/getmap?name=`+name+'&&date='+date+'&&instrument='+instrument);
  }
  getnull(name) {
    return http.get(`/firms/getnull?name=`+name);
  }
  getfirm(id) {
    return http.get(`/firms/` + id);
  }
  updatelocation(id, data) {
    return http.put(`/firms/updatelocation/${id}`, data);
  }
  updatefirm(id, data) {
    return http.put(`/firms/${id}`, data);
  }
  deletefirm(id) {
    return http.delete(`/firms/` + id);
  }
}

export default new FirmsService();