<template>
 <div class="container mt-5">
      <table class="table mt-3" v-if="list.length > 0">
        <thead>
          <tr>
            <th scope="col">ลำดับ</th> 
            <th scope="col">ชื่อ</th>    
            <th scope="col">สถานะ</th>    
          </tr>
        </thead>
        <tbody>
          <tr v-for="(l, i) in pageOfItems" :key="i">    
            <td>{{ l.id }}</td>       
             <td>{{ l.name }}</td>
             <td>{{ l.status }}</td>
          </tr>
        </tbody>
      </table>
      <div class="row" align="right">
        <div class="col-md-12">
          <jw-pagination
            :items="list"
            @changePage="onChangePage"
            :labels="customLabels"
            :pageSize=10
          ></jw-pagination>
        </div>
      </div>
      <div v-if="list.length == 0" class="mt-5">
<h3 style="text-align:center">ไม่พบข้อมูล</h3>
      </div>
  </div>
</template>


<script>
import axios from "axios";

const customLabels = {
  first: "<<",
  last: ">>",
  previous: "<",
  next: ">",
};

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      pageOfItems: [],
      list:[],
      customLabels
    };
  },
  mounted() {
    axios.get('https://finalspaceapi.com/api/v0/character/?limit=30').then((res) => {
  this.list = res.data
  console.log(res);
})
.catch((error) => {
  console.log(error);
});
  },
  methods: {
    onChangePage(pageOfItems) {
      this.pageOfItems = pageOfItems;
      window.scrollTo(0,0);
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
img {
  display: inline !important;
}
.qrcode {
  display: inline-block !important;
  font-size: 0;
  margin-bottom: 0;
  position: relative;
}
</style>
