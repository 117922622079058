<template>
  <div class="container">
    <div class="form-group mt-5 container-fluid">
    <label for="exampleInputEmail1">Date : </label>&nbsp;
    <input type="date" v-model="date" @change="changefirms()">&nbsp;
    <button type="button" class="btn btn-lg btn-secondary" @click="clear()">Clear</button>
  </div>
  <div class="row mt-5">
    <table class="table">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Date</th>
      <th scope="col">Instrument</th>
      <th scope="col">Satellite</th>
      <th scope="col">latitude</th>
      <th scope="col">longitude</th>
      <th scope="col">Location</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(l,i) in list" :key="i">
      <th scope="row">{{i+1}}</th>
      <td>{{l.dateth}}</td>
      <td>{{l.instrument}}</td>
      <td>{{l.satellite}}</td>
      <td>{{l.latitude}}</td>
      <td>{{l.longitude}}</td>
      <td>{{l.aoi}} {{l.subdistrict}} {{l.district}} {{l.province}}</td>
    </tr>
  </tbody>
</table>
  </div>
</div>
</template>

<script>
import FirmsService from "../services/FirmsService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  components:{
  },
  data() {
    return {
      list:[],
      date:''
    };
  },
  mounted() {
    this.getfirms()
  
  },
  methods: {
    changefirms(){
// console.log(this.date);
this.getfirms()
    },
    clear(){
      this.date = ''
this.getfirms()
    },
    getfirms(){
//       var d = new Date()
//       var date = d.getFullYear()+'-'+(parseInt(d.getMonth()) + 1).toString().padStart(2, "0")+'-'+(parseInt(d.getDate())-2).toString().padStart(2, "0")
// console.log(date);
      FirmsService.getfirms(56,'','',this.date).then((res)=>{
        console.log(res.data);
        this.list = res.data
        for (let l = 0; l < this.list.length; l++) {
          var date = new Date(this.list[l].acq_date)

var result = date.toLocaleDateString('th-TH', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
})
this.list[l].dateth =   result   
        }
      })
    }
    
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div{
  font-size: 14px;
}
</style>
