<template>
  <div class="container">
  <div class=" row mt-5">
    <div class="col-md-1">
    </div>
     <div class="card col-md-5">
      <Graph/>
    </div>
  </div>
</div>
</template>

<script>
import Graph from './Graph.vue'

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  components:{
    Graph,
  },
  data() {
    return {
     
    };
  },
  mounted() {
  
  },
  methods: {
    
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
