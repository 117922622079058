<template>
  <div class="container">
    <div class="card mt-5" style="width: 50%;">
  <div class="card-body">
    <h3 class="card-title" v-if="data.aoi">{{data.aoi}}</h3>
    <h4 class="card-text mt-5">latitude {{data.latitude}} longitude {{data.longitude}} <br>
    {{data.subdistrict}} {{data.district}} {{data.province}}</h4>
  </div>
</div>
   <!-- {{data}} -->
  </div>
</template>

<script>
// import longdo from "longdo-map-vue";
import FirmsService from "../services/FirmsService";
// import "@maptiler/sdk/dist/maptiler-sdk.css";
// import * as maptilersdk from '@maptiler/sdk';

export default {
  name: "App",
  components: {},
  data() {
    return {
      id:0,
      data:{},
      size:'',
      lng:'',
      lat:''
    };
  },
  methods: {
    // getmap() {
    //   const map = new maptilersdk.Map({
    //     container: 'map', // container's id or the HTML element to render the map
    //     style: maptilersdk.MapStyle.OUTDOOR,
    //     // center:[6.85684, 45.83595],
    //     center: [this.lng, this.lat], // starting position [lng, lat]
    //     zoom: 14, // starting zoom
    //     terrain: true,
    //     terrainControl: true,
    //     pitch: 61,
    //     bearing: 172,
    //     maxPitch: 85,
    //     // maxZoom: 14
    //   });
    //   document.getElementById("map").value = map
    // },
    getfirm() {
        FirmsService.getfirm(this.id)
        .then((res) => {
          console.log(res.data);
          this.data = res.data
        })
        .catch((e) => {
          console.log(e);
        });
      // console.log(this.markers);
      // });
      
    },
  },
  mounted() {
    this.id = this.$route.params.id
    console.log(this.$route.params.id);
    this.getfirm()
    this.size = 
'margin-top:'+window.innerHeight/10*2.5+'px'
    // maptilersdk.config.apiKey = '2pWrecVYhRzVG7Vj7wEJ';
    
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<style>
html,
body {
  font-size: 9px !important;
  height: 100%;
  margin: 0px;
}
</style>
